import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col, Image, Button } from "antd";
import { Link } from "react-router-dom";
import {
  APP_NAME,
  STATIC_SLIDER_OPTIONS,
  SLIDER_OPTIONS,
  SET_CATEGORIES,
  RECENT_BOOKS,
  FREE_BOOKS,
} from "../../constants";
import { Request } from "../../library";
import { SliderComponent } from "../../components";
import "../../resources/home.css";
import { useSelector } from "react-redux";
import { Input } from "antd";
import { Items } from "../../components";
import { SET_INSTITUTIONS } from "../../constants";
const SearchInput = Input.Search;
const HomeComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsData, setItemsData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(""); // Search keyword
  const { loading } = useSelector((state) => state.common);
  const { all: allItems } = useSelector((state) => state.institution);
  const getAllItems = async () => {
    const {
      data: { success, data },
    } = await Request.get("/api/institution/getAll");
    if (success) {
      dispatch({ type: SET_INSTITUTIONS, payload: data });
      setItemsData(data);
    }
  };
  useEffect(() => getAllItems(), []);
  /* Handle the search as per needed */
  const onSearch = (str = "") => {
    const keyword = str.replace(/\s/g, "").toLowerCase();
    // Handle filter for institutions
    if (searchKeyword !== keyword) {
      setSearchKeyword(keyword);
      if (!keyword) {
        return setItemsData(allItems);
      }
      const reg = new RegExp(keyword);
      const filteredItems = itemsData.filter((obj) => {
        const text = ["name"].reduce((acc, key) => {
          acc += obj[key] || "";
          return acc;
        }, "");
        return reg.test(text.replace(/\s/g, "").toLowerCase());
      });
      setItemsData(filteredItems);
    }
  };
  /* Handle the change in the search box */
  const onChange = (e) => {
    const keyword = e && e.target && e.target.value;
    if (!keyword) {
      onSearch("");
    }
  };
  const [homeData, setHomeData] = useState({
    recentBooks: [],
    freeBooks: [],
    topBooks: [],
    categories: [],
  });
  const getData = async () => {
    try {
      const {
        data: { success, data },
      } = await Request.get(`/api/home/getData`);

      if (success) {
        setHomeData({
          ...homeData,
          ...data,
        });
        dispatch({ type: SET_CATEGORIES, payload: data.categories || [] });
      }
    } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="bg-blue-100 h-auto md:h-screen block sm:flex p-5 md:p-10  text-center sm:items-center ">
        <Row className="flex-1 px-5 md:px-10 text-center sm:text-end">
          <Col>
            <h1 className="text-xl md:text-3xl ">Your education companion</h1>
            <h2 className="text-5xl md:text-7xl py-5 font-bold text-blue-700">
              {APP_NAME}
            </h2>
            <p className="py-5 leading-7 text-base">
              Welcome to Shineducation.com
            </p>
            <div className="buttons flex justify-center md:justify-end gap-5">
              <Link to="/institution-list">
                <Button className="btn bg-blue-600 px-5 py-3 h-auto hover:bg-blue-500 hover:shadow-2xl">
                  Find Institutions
                </Button>
              </Link>
              <Link to={"/book-store/all"}>
                <Button className="btn bg-blue-500 px-5 py-3 h-auto hover:bg-blue-600 hover:shadow-2xl">
                  Find Books
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="flex-1  ">
          <Image
            preview={false}
            src="./images/shine-education-banner.svg"
            height={400}
          />
        </Row>
      </div>
      {/* 1st slider section */}
      {homeData.recentBooks.length === 0 ? (
        "No Books Found"
      ) : (
        <>
          <SliderComponent
            showBtn
            showPrice
            showName
            btnLabel="Add to cart"
            wrapperClass=""
            settings={STATIC_SLIDER_OPTIONS}
            items={homeData.recentBooks}
          />
        </>
      )}

      <div className=" text-center bg-white py-10">
        <div className="search-wrapper">
          <SearchInput
            placeholder="Search institution by name"
            onSearch={onSearch}
            onChange={onChange}
            style={{ width: 350 }}
          />
        </div>
      </div>
      {!loading && (
        <Items
          showName
          itemsData={itemsData}
          wrapperClass="item-div-wrapper"
          selectItem={({ slug }) => navigate(`/institution-info/${slug}`)}
        />
      )}

      {/* 2nd slider section */}
      <div className="bg-white">
        <>
          <div className="">
            <SliderComponent
              showBtn
              showPrice
              btnLabel="Add to cart"
              h6Style={{ color: "white" }}
              wrapperClass="myslider"
              settings={SLIDER_OPTIONS}
              items={homeData.topBooks}
            />
          </div>
        </>
        {/* 3rd slider section */}
        {homeData.freeBooks.length === 0 ? (
          ""
        ) : (
          <>
            <SliderComponent
              showName
              showFree
              wrapperClass="myslider"
              settings={STATIC_SLIDER_OPTIONS}
              items={homeData.freeBooks}
            />
          </>
        )}
      </div>

      {/* 4th slider section */}
      <>
        <div
          className=" text-center p-10 bg-white text-3xl text-blue-600"
          onClick={() => navigate("/category-store")}
          title="Go to available categories page"
        >
          Available Categories
        </div>
        <SliderComponent
          showName
          wrapperClass="myslider category-slider"
          settings={STATIC_SLIDER_OPTIONS}
          items={homeData.categories}
        />
      </>
    </>
  );
};

export default HomeComponent;
